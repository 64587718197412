import React, { useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import House from "../../assets/house.png";
import Cards from "../../assets/card-transfers.png";
import Teams from "../../assets/teams.png";
import Pay from "../../assets/pay.png";
import "./InternationalTransfer.css";
import { MyContext } from "../../Context/AuthContext";
const InternationalTransfer = () => {
 
  return (
    <Box className="internationalBox" >
      <Typography className="interTitle">
        <span style={{ color: "#fff" }}>How to transfer money</span>{" "}
        internationally from the lebenon
      </Typography>
      <Box className="cardMoney">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
          <Card
              sx={{ background: "#1B1B1B", maxWidth: 290, height: 440, padding: "20px 0px" }}
            >
              <CardMedia
                sx={{
                  height: 240,
                  width: 180,
                  justifyContent: "center",
                  display: "flex",
                  margin: "auto",
                }}
                image={House}
                title="green iguana"
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom  className="bankTitle">
                  Bank Transfer
                </Typography>
                <Typography variant="body2" className="bankdesc">
                  Send and receive money through bank accounts (SWIFT & SEPA)
                  accessibility. T&C's apply.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Card
                 sx={{ background: "#1B1B1B", maxWidth: 290, height: 440, padding: "20px 0px" }}
                 >
              <CardMedia
                sx={{
                  height: 240,
                  width: 240,
                  justifyContent: "center",
                  display: "flex",
                  margin: "auto",
                }}
                image={Cards}
                title="green iguana"
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" className="bankTitle">
                  Card Transfer
                </Typography>
                <Typography variant="body2" className="bankdesc">
                  Transfer money in between Rival Card users
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Card
                sx={{ background: "#1B1B1B", maxWidth: 290, height: 440, padding: "20px 0px" }}
                >
              <CardMedia
                sx={{
                  height: 240,
                  width: 200,
                  justifyContent: "center",
                  display: "flex",
                  margin: "auto",
                }}
                image={Teams}
                title="green iguana"
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" className="bankTitle">
                  Peer Transfer
                </Typography>
                <Typography variant="body2" className="bankdesc">
                  Pay your Rival friends instantly, no more excuses.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Card
                sx={{ background: "#1B1B1B", maxWidth: 290, height: 440, padding: "20px 0px" }}
                >
              <CardMedia
                sx={{
                  height: 240,
                  width: 180,
                  justifyContent: "center",
                  display: "flex",
                  margin: "auto",
                }}
                image={Pay}
                title="green iguana"
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" className="bankTitle">
                  Wallets Transfer
                </Typography>
                <Typography variant="body2" className="bankdesc">
                  Send money to local and international wallets in a split
                  second, we are everywhere.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InternationalTransfer;
