import { Box, Typography } from '@mui/material';
import React from 'react';
import Phone from "../../assets/plus-mobile.png";

const Portfolio = () => {
  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, textAlign: "center" }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "30px", sm: "40px", md: "50px" },
          fontWeight: 700,
        }}
      >
        Portfolio
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
        <img src={Phone} alt='phone' style={{ width: "100%", maxWidth: "300px" }} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="body1"
          sx={{
            width: { xs: "90%", sm: "80%", md: "50%" },
            margin: "auto",
            lineHeight: 1.5,
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi porro minima nobis? Qui, consequatur ex! At maxime et qui, fugiat maiores, enim veritatis vitae possimus nam veniam nesciunt est corrupti! Quia dolore laudantium saepe quo aliquam quisquam porro fugiat laboriosam doloremque, minima officiis eum nam necessitatibus quas veritatis natus. Necessitatibus quisquam quidem voluptatem!
        </Typography>
      </Box>
    </Box>
  );
}

export default Portfolio;
