import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { GrSubtract } from "react-icons/gr";
import Printer from "../../assets/Vector (2).png";
import Printer2 from "../../assets/Vector (1).png";
import BlueRight from "../../assets/check 1.png";
import RedRight from "../../assets/check 1 (1).png";
import Rain from "../../assets/raibow.png";
import Cash from "../../assets/Group.png";
import "./Summary.css";

const Summary = () => {
  return (
    <Box>
      <Box className="summaryMain">
        <Typography className="summaryTitle">Summary</Typography>
      </Box>
      <Box>
        <Grid container spacing={8}>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
              <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Minimum deposit</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
            <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Minimum deposit</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
            <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Minimum deposit</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer} alt="print" width="auto" height="auto" />
            <Typography>International transfers</Typography>
            <Typography>Standard fees</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer} alt="print" width="auto" height="auto" />
            <Typography>International transfers</Typography>
            <Typography>10% discount</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer} alt="print" width="auto" height="auto" />
            <Typography>International transfers</Typography>
            <Typography>20% discount</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer2} alt="logo3" width="auto" height="auto" />
            <Typography>Card type</Typography>
            <Typography>virtual</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer2} alt="logo3" width="auto" height="auto" />
            <Typography>Card type</Typography>
            <Typography>virtual/physical</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Printer2} alt="logo3" width="auto" height="auto" />
            <Typography>Card type</Typography>
            <Typography>virtual/physical</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
            <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Exclusive cards</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={BlueRight} alt="right" width="auto" height="auto" />
            <Typography>Exclusive cards</Typography>
            <Typography>Available</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={RedRight} alt="right" width="auto" height="auto" />
            <Typography>Exclusive cards</Typography>
            <Typography>Available</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Rain} alt="right" width="auto" height="auto" />
            <Typography>Card colors</Typography>
            <Typography>4 colors</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Rain} alt="right" width="auto" height="auto" />
            <Typography>Card colors</Typography>
            <Typography>6 colors</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <img src={Rain} alt="right" width="auto" height="auto" />
            <Typography>Card colors</Typography>
            <Typography>8 colors</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
            <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Cashback</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
            <Button>
            <GrSubtract fontSize="2rem" color="#000" />
            </Button>
            <Typography>Cashback</Typography>
          </Grid>
          <Grid item xs={6} md={4} sm={6}>
      <img src={Cash} alt="logo" />
            <Typography>Cashback</Typography>
            <Typography>upto 10%</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Summary;
