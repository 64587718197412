import { Box, Button, Container, Typography } from '@mui/material'
import { GrSubtractCircle } from "react-icons/gr";

import React from 'react'

const International = () => {
  return (
   <Box>
      <Box>
        <Typography sx={{textAlign:"center", fontSize: { xs: "14px", md: "16px" }}}>
          Frequently Asked Questions
        </Typography>
        <Box sx={{width: "100%", justifyContent: "center", display: "flex", alignItems: "center", mt: "20px"}}>
          <Typography sx={{textAlign: "center", fontSize: { xs: "20px", md: "26px" }, fontWeight: "800", width: { xs: "90%", sm: "425px" }}}>
            International money transfer FAQs
          </Typography>
        </Box>
      </Box>

      <Box sx={{py: "50px"}}>
        {/* First FAQ */}
        <Box sx={{px: { xs: "20px", md: "190px" }, display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" }}}>
          <Box>
            <Typography sx={{color: "#000", fontSize: { xs: "18px", md: "20px" }, fontWeight: "700"}}>
              Who are Rival's owners
            </Typography>
          </Box>
          <Box>
            <Button><GrSubtractCircle color='#000'/></Button>
          </Box>
        </Box>
        <Box sx={{px: { xs: "20px", md: "200px" }, mt: "10px"}}>
          <Typography>
            Rival is a global financial solution that provides numerous services across the globe. Under Lebanese Jurisdiction, Rival is a subsidiary of Beirut Brokerage Corporation, a regulated brokerage for more than 26 years.
          </Typography>
        </Box>

        <Container>
          <Box sx={{borderBottom: "1px solid gray"}}></Box>
        </Container>

        {/* Second FAQ */}
        <Box sx={{px: { xs: "20px", md: "190px" }, display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" }, mt: "20px"}}>
          <Box>
            <Typography sx={{color: "#000", fontSize: { xs: "18px", md: "20px" }, fontWeight: "700"}}>
              Where is my real money located?
            </Typography>
          </Box>
          <Box>
            <Button><GrSubtractCircle color='#000'/></Button>
          </Box>
        </Box>
        <Box sx={{px: { xs: "20px", md: "200px" }, mt: "10px"}}>
          <Typography>
            With our global expertise and partnerships across the board, we have built a solution that is multifaceted across numerous countries including the UAE, Lithuania, Hong Kong, and other EU countries.
          </Typography>
        </Box>

        <Container>
          <Box sx={{borderBottom: "1px solid gray"}}></Box>
        </Container>

        {/* Third FAQ */}
        <Box sx={{px: { xs: "20px", md: "190px" }, display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" }, mt: "20px"}}>
          <Box>
            <Typography sx={{color: "#000", fontSize: { xs: "18px", md: "20px" }, fontWeight: "700"}}>
              Are savings accounts secure?
            </Typography>
          </Box>
          <Box>
            <Button><GrSubtractCircle color='#000'/></Button>
          </Box>
        </Box>
        <Box sx={{px: { xs: "20px", md: "200px" }, mt: "10px"}}>
          <Typography>
            With our approach of building clientele trust, we have numerous types of savings plans including unlocked savings, which give clients the ability to withdraw anytime throughout the day.
          </Typography>
        </Box>

        <Container>
          <Box sx={{borderBottom: "1px solid gray"}}></Box>
        </Container>
      </Box>
   </Box>
  )
}

export default International
