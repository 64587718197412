import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import Camera from "../../assets/Camera.png";
import MultiPhone from "./MultiPhone";
import Iphone from "./Iphone";
import Content4 from "../../compo/home/content4/Content4";
import ScrollSction from "./ScrollSction";
import Section5 from "./Section5";
import Section6 from "./Section6";
import FinanceApp from "../PersonelNavbar/FinanceApp";
import Plan from "../PersonelNavbar/Plan";
import Privacy from "../PersonelNavbar/Privacy";
import International from "./International";
import { MyContext } from "../../Context/AuthContext";

const CameraSection = () => {
  const theme = useTheme();
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));

  // Determine font size based on screen size
  let fontSize;
  if (isXs) fontSize = "24px"; // Extra small devices (phones)
  else if (isSm) fontSize = "32px"; // Small devices (tablets)
  else if (isMd) fontSize = "40px"; // Medium devices (small laptops)
  else if (isLg) fontSize = "48px"; // Large devices (desktops)
  else fontSize = "50px"; // Extra large devices (large desktops)
  return (
    <Box>
      <Box
        style={{ padding: "80px 0px", background: "#000" }}
        onMouseEnter={handlePopoverClose}
      >
        <Typography
          style={{
            color: "#fff",
            background: "#000",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Cards
        </Typography>
        <Typography
          style={{
            color: "#fff",
            textAlign: "center",
            fontWeight: "600",
            fontSize: fontSize,
          }}
        >
          Designed for you
        </Typography>

        <Box
          style={{
            background: "#000",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <img src={Camera} alt="logo" width="70%" height="70%" />
        </Box>
      </Box>
       <MultiPhone /> 
      <Iphone />
      <Box style={{marginTop: "-20px"}}>
      <Content4 />
      </Box>
      <Box style={{marginTop: "-120px"}}>
      <ScrollSction />
      </Box>
     
      <Section5 />
      <Section6 />
      <FinanceApp />

      <Privacy  title={"Privacy"}/>
      <Privacy title={"Security"} />
      <International />/
    </Box>
  );
};

export default CameraSection;
