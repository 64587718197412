import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure3 from '../../../component/structure-3/Structure3';
// import SafetyAlways from '../../../components/safety-always/SafetyAlways';
import classes from './Personal.module.css';
import image1 from '../../../assets/image1.png';
import image2 from '../../../assets/image2.png';
import image3 from '../../../assets/image3.png';
import image4 from '../../../assets/image4.png';
import image5 from '../../../assets/image5.png';
// import twophone from '../../../assets/phones.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';


const Personal = () => {
  // styles
  const propsTitle1 = {
    color: '#246BFD',
    textAllign: 'left'
  }
  const propsTitle2 = {
    color: '#030303',
    textAllign: 'left'
  }
  const propsDesc = {
    color: '#00000099',
    textAllign: 'left'

  }
  const propsImage = {
    maxWidth: '528px'
  }
  return (
    <div className={classes.personal_parent} >
      {/* <MainBanner /> */}
      <Structure2
        ptitle="Current Account"
        title="OVERCOME<br />YOUR CURRENT<br />SITUATUION"
        desc="No more excuses for where your salary is going, you can see<br />it all in front of your eyes. We are watching."
        btn="Open an account"
        // image={twophone}
        image="https://rival.finance/wp-content/uploads/2024/01/Group-1171278431.png"

        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <div className={classes.personal_center_parent}>
        <div className={classes.personal_title}>
          MAKE SHARING MONEY EASY
        </div>
        <p className={classes.personal_desc}>
        No need to leave your house anymore. Receive cash at your doorstep<br />
        and send money from your bed across the globe… easy.
        </p>
        <div className={classes.personal_image_container}>
          <img className={`${classes.image} ${classes.img1}`} src={image1} alt="img1" />
          <img className={`${classes.image} ${classes.img2}`} src={image2} alt="img2" />
          <img className={`${classes.image} ${classes.img3}`} src={image5} alt="img5" />
          <img className={`${classes.image} ${classes.img4}`} src={image4} alt="img4" />
          <img className={`${classes.image} ${classes.img5}`} src={image3} alt="img3" />
        </div>
      </div>
      {/* <SafetyAlways /> */}
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Personal