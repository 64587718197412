import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import React from "react";
import "./PersonelGooglePay.css";
import watch from "../../assets/watch.png";
import watch2 from "../../assets/watch.png";

const PersonelGooglePay = () => {
  return (
 

 
    <Box
      className="googlePay"
      
      sx={{ padding: { xs: 2, md: 5 }, overflow: "hidden" }}
    >
      <Box className="title" sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography
          sx={{
            fontFamily: "SF Pro Display",
            fontWeight: "600",
            fontSize: { xs: "32px", md: "55px" },
            color: "#fff",
            textAlign: "center",
          }}
        >
          Pay with Apple & Google pay
        </Typography>
        <Box className="secondTitle" sx={{ marginTop: 2 }}>
          <Typography
            sx={{
              fontFamily: "SF Pro Display",
              fontWeight: "600",
              fontSize: { xs: "16px", md: "22px" },
              color: "#505053",
              textAlign: "center",
              lineHeight: { xs: "20px", md: "28px" },
            }}
          >
            In publishing and graphic design Lorem ipsum may be used as a
            placeholder before the final copy is available.
          </Typography>
        </Box>
        <Box sx={{ alignItems: "center", justifyContent: "center", display: "flex", margin: "-50px auto", padding: "40px 0" }}>
        <Container maxWidth="xl">

     
        <Box
          sx={{
            padding: { xs: "0px 20px", md: "0px 70px" },
            marginTop: "50px",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  minWidth: { xs: 200, sm: 255 }, // Adjust for small screens
                  background: "#141414",
                  borderRadius: "15px",
                  maxWidth: "100%",
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "SF Pro Display",
                      fontWeight: "500",
                      fontSize: { xs: "15px", sm: "17px",md: "19px" }, // Responsive font size
                      color: "#505053",
                      textAlign: "center",
                      lineHeight: "25px",
                     
                    }}
                  >
                    <span style={{ color: "#fff" }}>
                      Document or a typeface
                    </span>{" "}
                    without relying on meaningful content. Lorem ipsum may be
                    used as a placeholder. ipsum may be used as a
                    placeholder.ipsum may be psum may be used as a
                    placeholder.ipsum ipsum may be used as a
                    placeholder.ipsum may
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      margin: "auto",
                      color: "#fff",
                      textTransform: "none",
                      border: "1px solid gray",
                      width: { xs: "80%", sm: "auto" }, // Responsive button width
                      
                    }}
                  >
                    Learn more
                  </Button>
                </CardActions>

                <CardActions
                  sx={{
                    justifyContent: "center",
                    margin: "auto",
                    height: {
                      xs: "50%", // Small screen size (e.g., mobile)
                      sm: "40%", // Medium screen size (e.g., tablets)
                      md: "auto", // Large screen size (e.g., desktop)
                    },
                  }}
                >
                  <Box sx={{ marginTop: { xs: "10px", sm: "15px",md:"20px" } }}>
                    <img
                      src={watch2}
                      alt="watch"
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        height: "auto",
                      }}
                    />
                  </Box>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  minWidth: { xs: 200, sm: 255 }, // Adjust for small screens
                  background: "#141414",
                  borderRadius: "15px",
                  maxWidth: "100%",
                  height: "100%",
                }}
              >
                <CardContent
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "SF Pro Display",
                      fontWeight: "500",
                      fontSize: { xs: "15px", sm: "17px",md: "19px" }, // Responsive font size
                      color: "#505053",
                      textAlign: "center",
                      lineHeight: "25px",
                     
                    }}
                  >
                    <span style={{ color: "#fff" }}>
                      Document or a typeface
                    </span>{" "}
                    without relying on meaningful content. Lorem ipsum may be
                    used as a placeholder. ipsum may be used as a
                    placeholder.ipsum may be psum may be used as a
                    placeholder.ipsum ipsum may be used as a
                    placeholder.ipsum may
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      margin: "auto",
                      color: "#fff",
                      textTransform: "none",
                      border: "1px solid gray",
                      width: { xs: "80%", sm: "auto" }, // Responsive button width
                      
                    }}
                  >
                    Learn more
                  </Button>
                </CardActions>

                <CardActions
                  sx={{
                    justifyContent: "center",
                    margin: "auto",
                    height: {
                      xs: "50%", // Small screen size (e.g., mobile)
                      sm: "40%", // Medium screen size (e.g., tablets)
                      md: "auto", // Large screen size (e.g., desktop)
                    },
                  }}
                >
                  <Box sx={{ marginTop: { xs: "10px", sm: "15px",md:"20px" } }}>
                    <img
                      src={watch2}
                      alt="watch"
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        height: "auto",
                      }}
                    />
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
        </Container>
        </Box>
      </Box>
    </Box>
  
  );
};

export default PersonelGooglePay;
