import React, { createContext, useState } from "react";

// Create a Context
export const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [state, setState] = React.useState("Hello, World!");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  return (
    <MyContext.Provider
      value={{
        anchorEl,
        setAnchorEl,
        handlePopoverOpen,
        handlePopoverClose,
        isPopoverOpen,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
