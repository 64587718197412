import { Box } from '@mui/material'
import Phones from "../../assets/mobiles.png"
import React from 'react'

const MobileSetion = () => {
  return (
    <Box style={{   backgroundClor: "#Fff "}}>
        <img src={Phones} alt='phone' width="100%" height="100%"/>
    </Box>
  )
}

export default MobileSetion