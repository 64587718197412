import { Box, Grid, Typography } from "@mui/material";
import Logo1 from "../../assets/communucation1.png";
import Logo2 from "../../assets/comminication2.png";
import Logo3 from "../../assets/comminication3.png";
import Logo4 from "../../assets/comminacation4.png";
import React from "react";
import "./Communication.css";
const Communication = () => {
  return (
    <Box className="communicationBox">
      <Typography className="communicationTitle">
        <span style={{ color: "#000" }}>Communication.</span> Apps to keep you
        connected.
      </Typography>
      <Box className="cardContainer">
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} sm={6}>
            <img src={Logo1} alt="logo1" width="100%" height="100%"  />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <img src={Logo2} alt="logo2"  width="100%" height="100%"  />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <img src={Logo3} alt="logo2"  width="100%" height="100%"  />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <img src={Logo4} alt="logo2"  width="100%" height="100%"  />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Communication;
