import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import Mobile from "../../assets/pro.png";
import watchs from "../../assets/blackwatch.png";
import Cards from "../../assets/blueCard.png";

const Section5 = () => {
  return (
    <Grid container spacing={2}>
      <Box
        sx={{
          bgcolor: "#f5f7fa",
          p: { xs: 2, md: 4 },
          minHeight: "100vh",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <Card
              sx={{
                width: { xs: "100%", sm: "80%", md: "90%" },
                borderRadius: 8,
                boxShadow: 3,
                bgcolor: "#fff",
                color: "#fff",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  src={Mobile}
                  alt="Mobile"
                  style={{ width: { xs: "100%", sm: "0%", md: "0%" }, }}
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: { xs: "14px", sm: "15px", md: "16px" },
                    fontWeight: "500",
                    width: "100%",
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Maiores officia veniam provident, nisi voluptas ratione
                  officiis nulla hic? Incidunt ex mollitia aliquid sapiente
                  placeat tempore.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", mb: 2 }}>
              <Card
                sx={{
                  height: { xs: "auto", md: "340px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: 8,
                  boxShadow: 3,
                  width: "100%",
                  padding: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: { xs: "14px", sm: "15px", md: "16px" },
                    fontWeight: "500",
                    mb: 2,
                  }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Assumenda earum atque accusamus. Dolore necessitatibus debitis
                  nesciunt.
                </Typography>
                <img
                  src={watchs}
                  alt="Watch"
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </Card>
            </Box>

            <Box sx={{ width: "100%", mt: 2 }}>
              <Card
                sx={{
                  height: { xs: "auto", md: "340px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: 8,
                  boxShadow: 3,
                  width: "100%",
                  padding: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: { xs: "14px", sm: "15px", md: "16px" },
                    fontWeight: "500",
                    mb: 2,
                  }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Assumenda earum atque accusamus. Dolore necessitatibus debitis
                  nesciunt.
                </Typography>
                <img
                  src={Cards}
                  alt="Card"
                  style={{ maxHeight: "60%", maxWidth: "100%" }}
                />
              </Card>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Section5;
