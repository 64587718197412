import React from 'react';
import { Box, Typography } from '@mui/material';
import Sto from "../../assets/SSSS.png"

const Stock = () => {
  return (
    <Box
    sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      background: 'linear-gradient(180deg, #ff005e 0%, #000 60%, #050505 100%)',
    //   backgroundImage: `url("../../assets/Frame.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      color: '#fff',
      padding: '20px',
      boxSizing: 'border-box',
    }}
  >
   <Box>
    <img src={Sto} alt='logo' />
   </Box>

    <Box
      component="img"
      src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
      alt="Apple Logo"
      sx={{
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        padding: '20px',
        marginBottom: '20px',
      }}
    />

    <Typography
      variant="body1"
      sx={{
        fontSize: {
          xs: '0.8rem',
          sm: '1rem',
          md: '1.2rem',
          color: "#fff",
        },
        maxWidth: '600px',
      }}
    >
      In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual
      form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder
      before the final copy is available.
    </Typography>
  </Box>
  );
};

export default Stock;
