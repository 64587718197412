import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Grid, Typography } from "@mui/material";
import "./CountrySelect.css";
import { CountryCode } from "../../assets/Country";

const CountrySelect = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Set the expanded panel or close it
  };
  
  return (


    <Box className="countryBox">
    <Box className="countryTitle">
      <Typography className="head">120+ Countries. 50+ Currencies</Typography>
    </Box>
    <Box className="countryDescription">
      <Typography className="para">
        From Beirut to Montreal, and almost all countries in between, send
        money however you like. The world is in between your fingertips,
        literally...
      </Typography>
    </Box>
    <Box className="accordianBox">
      <Accordion expanded={expanded === 'panel1'} onChange={handleToggle('panel1')} className="first">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Europe</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {expanded === 'panel1' && (
       <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: "13px" }}>
       {CountryCode.map((country, index) => (
         <div key={index} style={{ display: "flex", alignItems: "center", flex: "0 1 calc(33.33% - 10px)", marginBottom: "10px", justifyContent: "center" }}>
           <img
             src={country.icon}
             alt={`${country.label} flag`}
             style={{ width: "24px", height: "16px", marginRight: "10px" }}
           />
           <Typography style={{ width: "40%", fontSize: "12px" }}>{country.label}</Typography>
         </div>
       ))}
     </div>
       
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleToggle('panel2')} className="first">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Asia</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {expanded === 'panel2' && (
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: "13px" }}>
            {CountryCode.map((country, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", flex: "0 1 calc(33.33% - 10px)", marginBottom: "10px", justifyContent: "center" }}>
                <img
                  src={country.icon}
                  alt={`${country.label} flag`}
                  style={{ width: "24px", height: "16px", marginRight: "10px" }}
                />
                <Typography style={{ width: "40%", fontSize: "12px" }}>{country.label}</Typography>
              </div>
            ))}
          </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleToggle('panel3')} className="first">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>Africa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {expanded === 'panel3' && (
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", gap: "13px" }}>
              {CountryCode.map((country, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", flex: "0 1 calc(33.33% - 10px)", marginBottom: "10px", justifyContent: "center" }}>
                  <img
                    src={country.icon}
                    alt={`${country.label} flag`}
                    style={{ width: "24px", height: "16px", marginRight: "10px" }}
                  />
                  <Typography style={{ width: "40%", fontSize: "12px" }}>{country.label}</Typography>
                </div>
              ))}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  </Box>
  );
};

export default CountrySelect;
