import React, { useContext } from "react";
import "./RivalPlan.css";
import { Box, Button, Grid, Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import RivalCard from "./RivalCard";
import Summary from "./Summary";
import Financial from "./Financial";
import Refferal from "./Refferal";
import CameraRival from "./CameraRival";
import International from "../Card/International";
import { MyContext } from "../../Context/AuthContext";

const RivalPlan = () => {
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);
  return (
    <Box className="rivalTitle"onMouseEnter={handlePopoverClose}>
      <Typography className="comTitle">Compare Rival plans</Typography>
      <Box className="rivaldesc">
        <Typography className="comDesc">Get help choosing<span><Button>Chat with a Specialist<MdKeyboardArrowRight /></Button></span></Typography>
     
      </Box>
      <RivalCard />
       <Summary />
      <Box marginTop="80px">
      <Financial />
      <CameraRival />
      <Box marginTop="80px">

    
      <International />
      </Box>
      </Box>
     
   
    </Box>
  );
};

export default RivalPlan;
