import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const MultiPhone = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger screens
        justifyContent: { sm: "space-between" },
        alignItems: "center",
        borderBottom: "1px solid gray",
        padding: { xs: "2%", sm: "3% 5% 2% 10%" }, // Adjust padding based on screen size
        gap: { xs: "20px", sm: "10px" }, // Add gap between elements for smaller screens
        width: "100%", // Ensures the box scales
      }}
    >
      {/* Title */}
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
            fontSize: { xs: "20px", sm: "24px", md: "28px" }, // Responsive font size for better readability
          }}
        >
          iPhone 16
        </Typography>
      </Box>

      {/* Links Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger screens
          gap: { xs: "20px", sm: "40px" }, // Consistent gap for both layouts
          alignItems: "center",
          justifyContent: { sm: "flex-end" }, // Align links properly on larger screens
        }}
      >
        {/* Overview Link */}
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: { xs: "16px", sm: "18px" },
              fontFamily: "sans-serif",
            }}
          >
            <Link
              to="/overview"
              style={{
                textDecoration: "none",
                color: "gray",
                transition: "color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.color = "black")}
              onMouseLeave={(e) => (e.target.style.color = "gray")}
            >
              Overview
            </Link>
          </Typography>
        </Box>

        {/* Switch from Android Link */}
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            <Link
              to="/switch"
              style={{
                textDecoration: "none",
                color: "gray",
                transition: "color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.color = "black")}
              onMouseLeave={(e) => (e.target.style.color = "gray")}
            >
              Switch from Android to iPhone
            </Link>
          </Typography>
        </Box>

        {/* Tech Spec Link */}
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            <Link
              to="/tech-spec"
              style={{
                textDecoration: "none",
                color: "gray",
                transition: "color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.color = "black")}
              onMouseLeave={(e) => (e.target.style.color = "gray")}
            >
              Tech Spec
            </Link>
          </Typography>
        </Box>

        {/* Pricing Button */}
        <Box
          sx={{
            width: { xs: "100%", sm: "auto" }, // Full-width on small screens
          }}
        >
          <Button
            sx={{
              backgroundColor: "#246BFD",
              color: "#fff",
              fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
              borderRadius: "20px",
              textTransform: "none",
              padding: "10px",
              width: { xs: "100%", sm: "auto" }, // Full width for mobile, auto for larger screens
              "&:hover": {
                backgroundColor: "#1a53c1",
              },
            }}
          >
            View Pricing
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MultiPhone;
