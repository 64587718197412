import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Footer.module.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import Reval from '../../assets/r-logo.png';
import InternationalBtn from '../international-btn/InternationalBtn';
import { MyContext } from '../../Context/AuthContext';

const Footer = () => {
  
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling
    });
  };

  const navigateToPage = (pageId) => {
    navigate(`/?page_id=${pageId}`);
  }

  const handleClick = (pageId) => {
    scrollToTop(); // Scroll to the top
    navigateToPage(pageId); // Navigate to the desired page
  };
  const {  anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen}  = useContext(MyContext)
  return (
    <footer onMouseEnter={handlePopoverClose}>
      <h2 className={classes.footer_title_1}>What are you waiting for?</h2>
      <h1 className={classes.footer_title_2}>Empower your money</h1>
      <InternationalBtn title="Get The App" />
      <div className={classes.footer_content}>
        <div className={classes.footer_content_section}>
          <h4>Accounts</h4>
          <ul>
            <li onClick={() => handleClick(1)}>Current</li>
            <li onClick={() => handleClick(5)}>Savings</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Finances</h4>
          <ul>
            <li onClick={() => handleClick(6)}>Cards</li>
            <li onClick={() => handleClick(7)}>Transfers</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Investing</h4>
          <ul>
            <li onClick={() => handleClick(8)}>Crypto</li>
            <li onClick={() => handleClick(9)}>Stocks & Metals</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Lifestyle</h4>
          <ul>
            <li onClick={() => handleClick(10)}>Cashback</li>
            <li onClick={() => handleClick(11)}>refferals</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Plans</h4>
          <ul>
            <li onClick={() => handleClick(12)}>Standard</li>
            <li onClick={() => handleClick(13)}>Plus</li>
            <li onClick={() => handleClick(14)}>Pro</li>
            <li onClick={() => handleClick(15)}>Compare Plans</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Help</h4>
          <ul>
            <li onClick={() => handleClick(16)}>Customer support</li>
            <li onClick={() => handleClick(17)}>Terms</li>
          </ul>
        </div>
        <div className={classes.footer_content_section}>
          <h4>Company</h4>
          <ul>
            <li onClick={() => handleClick(3)}>About us</li>
            <li onClick={() => handleClick(19)}>Contact</li>
            <li onClick={() => handleClick(20)}>Security</li>
            <li onClick={() => handleClick(21)}>Code of Conduct</li>
            <li onClick={() => handleClick(22)}>News & Media</li>
          </ul>
        </div>
      </div>
      <div className={classes.footer_logos}>
        <img src={Reval} alt="R" />
        
    <div className={classes.footer_social_icons}>
      <div className={classes.footer_icon_container}>
        <FaFacebookF className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaTwitter className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaInstagram className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaLinkedinIn className={classes.footer_icon} />
      </div>
    </div>
      </div>
      <hr />
      {/* <p className={classes.footer_paraa}></p> */}
      <p className={classes.footer_para}>Copyright © 2024 Rival All rights reserved.</p>
      <p className={classes.footer_para}>Rival Payment Services Provider Co. L.L.C., operating under the name Rival Finance, is registered in the United Arab Emirates, established by the Dubai Ministry of Economy and Trade (License No. 1308095). Specializing in card issuance and acquiring services, Rival Finance is dedicated to facilitating comprehensive card programs, both on a local and international scale, in strict adherence to regulatory standards and professional excellence.</p>
      <p className={classes.footer_para}>
      Beirut Brokerage Corporation, serving as the designated investing broker for Rival Finance, is duly licensed in Lebanon by the Capital Markets Authority (License No. 8). Located in Verdun, Beirut, the corporation specializes in providing premium investing services, ensuring compliance with the highest regulatory standards and professional practices within the financial industry.</p>
    </footer>
  );
}

export default Footer;


