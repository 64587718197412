import React, { useContext, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';
// import "./Container3.css"
import image1 from '../../assets/iphone-15.png';
import image2 from '../../assets/rivalcrads.png';
import image3 from '../../assets/pro-mobile.png';

import { MyContext } from '../../Context/AuthContext';

gsap.registerPlugin(ScrollTrigger);

function ScrollSction() {
  const workRef = useRef(null);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    lenis.on('scroll', ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    const workInfoItems = document.querySelectorAll('.work__photo-item');
    workInfoItems.forEach((item, index) => {
      item.style.zIndex = workInfoItems.length - index;
    });

    const animation = gsap.to('.work__photo-item:not(:last-child)', {
      clipPath: 'inset(0px 0px 100% 0px)',
      stagger: 0.5,
      ease: 'none',
    });

    ScrollTrigger.create({
      trigger: workRef.current,
      start: 'top top',
      end: 'bottom bottom',
      animation: animation,
      scrub: 1,
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);

  return (
    <div className="wrapp" onMouseEnter={handlePopoverClose}>
      <section className="work" ref={workRef}>
        <div className="work__left">
          <div className="work__text">
            {['Plus', 'Pro', 'Standard'].map((plan, index) => (
              <div className="work__info" key={index}>
                <label>Explore plans</label>
                <h2>{plan}</h2>
                <p>
                  In publishing and graphic design,{' '}
                  <span>
                    Lorem ipsum is a placeholder text commonly used to demonstrate the
                    visual form of a document or a typeface
                  </span>{' '}
                  without relying on meaningful content. Lorem ipsum may be used as a
                  placeholder before the final copy is available.
                </p>
                <div className="buttons">
                  <button>Get the app</button>
                  <a href="#">Learn more</a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="work__right">
          <div className="work__right-bl">
            <div className="work__photo">
              {[image2, image3, image1].map((src, index) => (
                <div className="work__photo-item" key={index}>
                  <img src={src} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ScrollSction;
