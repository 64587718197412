import React, { useContext } from "react";
import "./IInvest.css";
import Micro from "../../assets/sctuim.png";
import { Box, Typography } from "@mui/material";
import Stock from "./Stock";
import Crypto from "./Crypto";
import Communication from "../Transfer/Communication";
import Portfolio from "./Portfolio";
import International from "../Card/International";
import FinanceApp from "../PersonelNavbar/FinanceApp";
import { MyContext } from "../../Context/AuthContext";


const IInvest = () => {
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);
  return (
    <Box onMouseEnter={handlePopoverClose}>


    <Box className="investBox">
      <img src={Micro} alt="logo" width="100%" height="100%" />
      <Box className="investTitle">
        <Typography className="mainTitle">Investment</Typography>
        <Typography className="subTitle">Invest with Rival</Typography>
      </Box>
  
    </Box>
    <Stock />
    <Crypto />
   <Communication />
     <Portfolio />
    <International />
    <FinanceApp />
    </Box>
  );
};

export default IInvest;
