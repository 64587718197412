import { Box } from '@mui/material'
import React from 'react'
import Camera from "../../assets/Camera.png"

const CameraRival = () => {
  return (
<Box marginTop="100px">
    <img src={Camera} alt='camera' width="100%"  height="100%"/>
</Box>
  )
}

export default CameraRival