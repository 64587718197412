import React, { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import classes from "./Header.module.css";
import Rival from "../../assets/r-logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import Popover from "@mui/material/Popover";
import { Box, Typography } from "@mui/material";
import personelhover from "../../assets/personelhover.png";
import { MyContext } from "../../Context/AuthContext";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const open = Boolean(anchorEl);
  const handleCardClick = () => {
    Navigate("/?page_id=12"); 
  };

  return (
    <header className={classes.header} style={{position: "relative"}}>
      <Link to="/">
        <img src={Rival} alt="R" />
      </Link>
      <nav className={`${classes.nav} ${isOpen ? classes.open : ""}`}>
        <ul style={{ top: "15px" }}>
          <li
            onMouseEnter={handlePopoverOpen} // Open popover on mouse enter
            // onMouseLeave={handlePopoverClose} // Close popover on mouse leave
            onClick={toggleMenu}
            // Set position relative for the submenu
          >
            <Link to="/?page_id=23">Personal</Link>
            {/* <Box mt={4} style={{position: "relative"}}>
          
            </Box> */}
          </li>
          <li onClick={toggleMenu}>
            <Link to="/?page_id=2">Business</Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/?page_id=3">Company</Link>
          </li>
        </ul>
        <FaTimes className={classes.closeIcon} onClick={toggleMenu} />
      </nav>

      <FaBars className={classes.menuIcon} onClick={toggleMenu} />
      {isPopoverOpen && (
                <ul
             
                style={{
                background: "#fff",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: "auto",
                textDecoration: "none",
                gap: "55px",
                position: "absolute",
                flexDirection: window.innerWidth <= 768 ? "column" : "row",
                top: "100%",
                left: "0rem",
                width: "100%",
         
           
                zIndex: 1000,
                padding: "12px",
                // width: "100vw", // You can adjust this width
               
              }}
                  
                >
                  <li style={{listStyleType: "none"}}>
                    <img src={personelhover} alt="logo" />
                    <Typography >
                      <Link to="/?page_id=5" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Savings</Link>
                    </Typography>
                  </li>
                  <li style={{listStyleType: "none"}}>
                    <img src={personelhover} alt="logo" />
                    <Typography>
                      <Link to="/?page_id=24" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Cards</Link>
                    </Typography>
                  </li>
                  <li style={{listStyleType: "none"}}>
              <img src={personelhover} alt="logo" />
              <Typography>
              <Link to="/?page_id=25" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Transfer</Link>
              </Typography>
              
              
              </li>
              <li style={{listStyleType: "none"}}>
              <img src={personelhover} alt="logo" />
              <Typography>
              <Link to="/?page_id=26" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Investing</Link>
              </Typography>
              
              
              </li>
              <li style={{listStyleType: "none"}}>
              <img src={personelhover} alt="logo" />
              <Typography>
              <Link to="/?page_id=27" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Compare</Link>
              </Typography>
              
              
              </li>
              <li style={{listStyleType: "none"}}>
              <img src={personelhover} alt="logo" />
              <Typography>
              <Link to="/?page_id=12" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Plans</Link>
              </Typography>
              
              
              </li>
              <li style={{listStyleType: "none"}}>
              <img src={personelhover} alt="logo" />
              <Typography>
              <Link to="/?page_id=20" style={{textDecoration: "none", color: "#000"}} onClick={handlePopoverClose}>Security</Link>
              </Typography>
              
              
              </li>
                </ul>
              )}
    </header>
  );
};

export default Header;
