import { Box, Typography, Grid } from '@mui/material';
import BlueCard from "../../assets/blueRival.png";
import React from 'react';
import "./Section6.css";

const Section6 = () => {
  return (
    <Box width="100%">
      <Grid container spacing={2} sx={{ padding: { xs: "10px", sm: "20px 40px", md: "20px 120px" } }}>
        <Grid item xs={12}>
          <Typography 
            className="gradient-text" 
            sx={{ fontSize: { xs: "24px", sm: "30px" }, fontWeight: "600" }}>
            Cards with so many colors.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography 
            sx={{ fontSize: { xs: "28px", sm: "35px" }, width: { xs: "100%", sm: "50%" }, fontWeight: "600", lineHeight: "30px" }}>
            Go to great lengths to get the perfect shot.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography 
            sx={{ width: { xs: "100%", sm: "60%" } }}>
            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'start', padding: { xs: "0", sm: "20px 0" } ,marginLeft:{xs: "0", sm: "0",md: "-120px"}}}>
            <img src={BlueCard} alt='card' style={{ width: "100%", maxWidth: "600px" }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Section6;
