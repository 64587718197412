import {
  Box,
  Card,
  Typography,
  CardContent,
  CardMedia,
  Button,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Slider from "react-slick";
import halfCircle from "../../assets/halfCircle.png";
import "./FinanceApp.css";
import "slick-carousel/slick/slick.css";
import { IoIosArrowBack, IoMdAdd } from "react-icons/io";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";


const cardsData = [
  {
    id: 1,
    log: halfCircle,
    title: "Save with Rival finance.",
    para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate placeholder text.",
  },
  {
    id: 2,
    log: halfCircle,
    title: "Save with Rival finance.",
    para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate placeholder text.",
  },
  {
    id: 3,
    log: halfCircle,
    title: "Save with Rival finance.",
    para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate placeholder text.",
  },
  {
    id: 4,
    log: halfCircle,
    title: "Save with Rival finance.",
    para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate placeholder text.",
  },
  {
    id: 5,
    log: halfCircle,
    title: "Save with Rival finance.",
    para: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate placeholder text.",
  },
];

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Checks if screen is small (below "md" size)
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg")); // Medium screens
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Large screens

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: isSmallScreen ? "50%" : isMediumScreen ? "45%" : "374px", // Adjusts top position based on screen size
        right: isSmallScreen ? "0%" : isMediumScreen ? "35px" : "40px", // Adjusts right position for responsiveness
        background: "#DEDEE2",
        // background: "red",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        zIndex: 1,
        transform: "translateY(-50%)", // Centers vertically
        
      }}
      onClick={onClick}
    >
      {/* <IoIosArrowForward style={{ color: "#fff", fontSize: "1.5rem" }} /> */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",

        top: isSmallScreen ? "50%" : isMediumScreen ? "45%" : "374px",
        left: isSmallScreen ? "3%" : isMediumScreen ? "0px" : "1280px",
        background: "#DEDEE2",
       
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        zIndex: 1,
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    >
      {/* <IoIosArrowBack style={{ color: "#fff", fontSize: "1.5rem" }} /> */}
    </div>
  );
};


const FinanceApp = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); 
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    // Custom next/prev arrows
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      className="mainBox"
      sx={{
        padding: { xs: "10px", md: "20px" },
        maxWidth: "100vw", // Ensure the box doesn't overflow
        overflow: "hidden", // Hide overflow if it goes beyond viewport
      }}
    >
        <Box
        sx={{
          padding: isSmallScreen ? "0px 20px" : "0px 75px",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          textAlign: isSmallScreen ? "center" : "left",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
        className="lineTitle"
          sx={{
           
            fontWeight: "700",
            fontSize: isSmallScreen ? "28px" : "40px",
            marginBottom: isSmallScreen ? "20px" : "0",
          }}
        >
       Why Rival is the best finance
        </Typography>

        <Button
          sx={{
            textTransform: "none",
            fontSize: isSmallScreen ? "14px" : "16px",
          }}
        >
          Compare all plans
          <IoIosArrowForward />
        </Button>
      </Box>
      <Box sx={{ alignItems: "center", justifyContent: "center", display: "flex", margin: "-50px auto", padding: "40px 0" }}>
        <Container maxWidth="xl">
          <Box sx={{ overflow: "hidden", position: "relative" }}>
            <Slider {...settings} className="cardsContainer">
              {cardsData.map((card, index) => (
                <Box
                  key={card.id}
                  sx={{
                    padding: "20px",
                    boxSizing: "border-box",
                    margin: "0 auto", // Adjust margins to center the cards
                  }}
                >
                  <Card
                    className="cardBox"
                    sx={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "auto", // Allow the height to adjust automatically
                      width: "100%",
                      maxWidth: { xs: "100%", md: "350px" }, // Make sure the card respects the parent container's width
                      boxSizing: "border-box",
                      margin: "0 auto", // Center card content
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={card.log}
                      alt="Card image"
                      className="logo"
                      sx={{
                        marginBottom: "20px",
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: { xs: "1.3rem", md: "1.6rem" },
                          fontFamily: "SF Pro Display",
                          fontWeight: "600",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          marginTop: "10px",
                          fontFamily: "SF Pro Display",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: { xs: "1.2rem", md: "1.3rem" },
                        }}
                      >
                        {card.para}
                      </Typography>
                    </CardContent>

                    <Button
                      sx={{
                        alignSelf: "flex-end",
                        marginTop: "auto",
                        background: "#000",
                        height: { xs: "30px", md: "10px" }, // Increased button size for better visibility
                        width: { xs: "30px", md: "10px" },
                        minHeight: "30px",
                        minWidth: "30px",
                        borderRadius: "50%",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                          background: "#333",
                        },
                      }}
                    >
                      <IoMdAdd fontSize="1rem" />
                    </Button>
                  </Card>
                </Box>
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default FinanceApp;
