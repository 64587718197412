import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import "./PersonelSection1.css"; // Correctly import CSS file
import Phone from "../../assets/personalPhone.png";
import PersonelPargraph from "./PersonelPargraph";
import Content4 from "../../compo/home/content4/Content4";
import Content3 from "../../compo/home/content3/Content3";
import PersonelGooglePay from "./PersonelGooglePay";
import FinanceApp from "./FinanceApp";
import Plan from "./Plan";
import Privacy from "./Privacy";
import { MyContext } from "../../Context/AuthContext";

const PersonelSection1 = () => {
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);

  const imageRef = useRef(null);
  let zoom = 1;
  const ZOOM_SPEED = 0.1;

  useEffect(() => {
    const handleWheel = (e) => {
      // Get current screen width
      const screenWidth = window.innerWidth;

      // Only apply zoom if the screen width is outside of the 320px to 768px range
      if (screenWidth < 320 || screenWidth > 768) {
        if (imageRef.current) {
          if (e.deltaY > 0) {
            zoom += ZOOM_SPEED;
          } else {
            zoom -= ZOOM_SPEED;
          }
          // Limit zoom levels
          zoom = Math.max(1, Math.min(zoom, 3));
          imageRef.current.style.transform = `scale(${zoom})`;
        }
      }
    };

    window.addEventListener("wheel", handleWheel);
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <Box className="section1" onMouseEnter={handlePopoverClose}>
      <Box className="sectionImage" ref={imageRef}>
        <img src={Phone} alt="phone" />
      </Box>
      <PersonelPargraph />
      <Content4 />
      <Content3 />
      <Box sx={{ marginTop: "-70px" }}>
        <PersonelGooglePay />
      </Box>
      <FinanceApp />
      <Plan />
      <Privacy title={"Privacy"} />
      <Privacy title={"Security"} />
    </Box>
  );
};

export default PersonelSection1;
