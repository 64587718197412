import React, { useContext } from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure4 from '../../../component/structure-4/Structure4';
import locker from '../../../assets/locker.png';
import tcircle from '../../../assets/circle-triangle.png';
import FourCards from '../../../component/four-cards/FourCards';
import card1 from '../../../assets/card1.png';
import card2 from '../../../assets/card2.png';
import card3 from '../../../assets/card3.png';
import card4 from '../../../assets/card4.png';
import { MyContext } from '../../../Context/AuthContext';

const Security = () => {
  // style first
  const propsTitle1 = {
    color: '#246BFD'
  }
  const propsTitle2 = {
    color: '#030303'
  }
  const propsDesc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '500px'
  }
  // style second
  const propsImage_2 = {
    maxWidth: '400px'
  }
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);
  return (
    <div onMouseEnter={handlePopoverClose}>
      <Structure2
        ptitle="Security"
        title="FULL STACK<br />SECURITY"
        desc="Partnerships with leading global financial institutions including DBS Hong Kong, Barclays"
        btn="Visit the app"
        image={locker}

        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <Structure4
        title2="TOOLS YOU CAN TRUST"
        desc="Our custom-built technology gives you everything you need to be a
        scammer’s worst nightmare. With easy in-app tools, get
        security that goes beyond."
        btn="Learn More"
      />
      <FourCards
        img1={card1}
        img2={card2}
        img3={card3}
        img4={card4}
      />
      <Structure2
        ptitle="Security"
        title="FULL STACK<br />SECURITY"
        desc="Trusted by 35+ million global customers for all things money,<br />
        we offer you end-to-end protection 24/7."
        btn="Visit the app"
        image={tcircle}

        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage_2}
      />
    </div>
  )
}

export default Security