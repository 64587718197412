import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import image1 from "../../assets/iphone-15.png";
import image2 from "../../assets/iphone-15.png";
import image3 from "../../assets/iphone-15.png";
import "./RivalCard.css";

const phoneList = [
  {
    id: 1,
    phoneLogo: image1,
    title: "PRO",
    description: "Tailored for the pros.",
    price: "From $5.99/mo",
  },
  {
    id: 2,
    phoneLogo: image2,
    title: "PLUS",
    description: "Tailored for the pros.",
    price: "From $5.99/mo",
  },
  {
    id: 3,
    phoneLogo: image3,
    title: "PRO",
    description: "Tailored for the pros.",
    price: "From $5.99/mo",
  },
];

const RivalCard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Check if the screen is smaller than 'md' (960px)

  return (
    <Box sx={{ background: "#fff", padding: "80px 0px", overflow: "hidden" }}>
      {/* Header section */}
   

      {/* Card section */}
      <Container maxWidth="xl" sx={{ padding: isSmallScreen ? "0 20px" : "0 80px" }}>
        <Grid container spacing={3} justifyContent="center">
          {phoneList.map((list) => (
            <Grid item xs={12} sm={6} md={4} key={list.id}>
              <Card
                sx={{
                  maxWidth: 430,
                  background: "#F5F5F7",
                  height: "100%",
                  borderRadius: "20px",
                  padding: "20px",
                  textAlign: "center",
                  paddingBottom: "40px",
                }}
              >
                <CardMedia
                  component="img"
                  image={list.phoneLogo}
                  alt={list.title}
                  sx={{
                    width: isSmallScreen ? "80%" : "60%",
                    height: "auto",
                    margin: "auto",
                  }}
                />

                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: isSmallScreen ? "18px" : "16px",
                    marginTop: "10px",
                  }}
                >
                  {list.title}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: isSmallScreen ? "16px" : "14px",
                    marginTop: "10px",
                    color: "#6b6b6b",
                  }}
                >
                  {list.description}
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: isSmallScreen ? "18px" : "16px",
                    marginTop: "10px",
                  }}
                >
                  {list.price}
                </Typography>

                <Button
                  type="submit"
                  sx={{
                    background: "#246BFD",
                    borderRadius: "30px",
                    color: "#fff",
                    textTransform: "capitalize",
                    width: "100%", // Full width on small screens
                    padding: "10px",
                    fontWeight: "600",
                    marginTop: "20px",
                    "&:hover": {
                      background: "#333",
                    },
                  }}
                >
                  Compare Plans
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default RivalCard;
