import React from "react";
import { Box, colors, Container, Typography, } from "@mui/material";
import Grid from '@mui/material/Grid';
import "./Privacy.css";
import Logo from "../../assets/Subtract.png"



const Privacy = ({title}) => {
  return (
  

 
    <Box sx={{alignItem: "center",justifyContent: "center",display: "flex",margin: "0px 80px",padding: "80px 0px"}}>
    <Container maxWidth="xl" marginTop = "100px">
      <Box className ="PrivacyMainBox">
        <Typography className="privacyTitle">{title}</Typography>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs={12} md={4} sm={12}>
            <img src={Logo} alt="logo" width="30px" height="30px" />
            <Typography className="privacyDesc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis provident vitae eum necessitatibus repellendus, aperiam laboriosam, ipsa cum quidem tempora suscipit, ab doloremque.<span style={{color:"#000"}}>Illo laudantium veritatis nam quis nulla laborum. Sequi ullam ducimus molestiae veritatis</span>  eius! Alias nostrum tenetur maxime aspernatur laudantium magni, quas odio recusandae cum illum architecto laboriosam deserunt. Dolor assumenda quae deleniti commodi non aut cumque vel rem saepe magnam illum, </Typography>
        </Grid>
        <Grid item xs={12} md={4} sm={12}>
        <img src={Logo} alt="logo" width="30px" height="30px" />
            <Typography className="privacyDesc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis provident vitae eum necessitatibus repellendus, aperiam laboriosam, ipsa cum quidem tempora suscipit, ab doloremque.<span style={{color:"#000"}}>Illo laudantium veritatis nam quis nulla laborum. Sequi ullam ducimus molestiae veritatis</span>  eius! Alias nostrum tenetur maxime aspernatur laudantium magni, quas odio recusandae cum illum architecto laboriosam deserunt. Dolor assumenda quae deleniti commodi non aut cumque vel rem saepe magnam illum, </Typography>
        </Grid>
        <Grid item xs={12} md={4} sm={12}>
        <img src={Logo} alt="logo" width="30px" height="30px" />
            <Typography className="privacyDesc">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis provident vitae eum necessitatibus repellendus, aperiam laboriosam, ipsa cum quidem tempora suscipit, ab doloremque.<span style={{color:"#000"}}>Illo laudantium veritatis nam quis nulla laborum. Sequi ullam ducimus molestiae veritatis</span>  eius! Alias nostrum tenetur maxime aspernatur laudantium magni, quas odio recusandae cum illum architecto laboriosam deserunt. Dolor assumenda quae deleniti commodi non aut cumque vel rem saepe magnam illum, </Typography>
        </Grid>
      </Grid>
    </Container>
    </Box>
 
  );
};

export default Privacy;
