import { Box } from "@mui/material";
import React from "react";
import AllPhones from "../../assets/iphone all color.png";

const Iphone = () => {
  return (
    <Box>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          padding: "0px 0px 70px 0px",
        }}
      >
        <img
          src={AllPhones}
          alt="phone"
          width="50%"
          height="50%"
          marginBottom="30px"
        />
      </Box>
    </Box>
  );
};

export default Iphone;
