// import React from 'react';
// import classes from './Content5.module.css';
// import phone from '../../../assets/rival-phone.png';
// import pay from '../../../assets/apple-pay.png';

// const Content5 = () => {
//   return (
//     <div className={classes.home_content5_parent}>
//         <div className={classes.home_content5_child1}>
//             <img src={phone} alt="phone-image" />
//         </div>
//         <div className={classes.home_content5_child2}>
//             <img src={pay} alt="apple-pay" />
//         </div>
//         <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to 
//         demonstrate the visual form of a document or a typeface without relying on meaningful content. 
//         Lorem ipsum may be used as a placeholder before the final copy is available</p>
//     </div>
//   )
// }

// export default Content5;




// import React, { useEffect, useRef, useState } from 'react';
// import classes from './Content5.module.css';
// import phone from '../../../assets/rival-phone.png';
// import pay from '../../../assets/apple-pay.png';

// const Content5 = () => {
//   const contentRef = useRef(null);
//   const [scale, setScale] = useState(1.5);

//   useEffect(() => {
//     const handleScroll = () => {
//       const rect = contentRef.current.getBoundingClientRect();
//       const windowHeight = window.innerHeight;

//       if (rect.top < windowHeight && rect.bottom > 0) {
//         const percentageVisible = Math.min(Math.max(0, (windowHeight - rect.top) / (windowHeight + rect.height * 0.5)), 1);
//         const newScale = 1 + 0.5 * (1 - percentageVisible);
//         setScale(newScale);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <div ref={contentRef} className={classes.home_content5_parent}>
//       <div className={classes.home_content5_child1} style={{ transform: `scale(${scale})` }}>
//         <img src={phone} alt="phone-image" />
//       </div>
//       <div className={classes.home_content5_child2}>
//         <img src={pay} alt="apple-pay" />
//       </div>
//       <p>
//         In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to 
//         demonstrate the visual form of a document or a typeface without relying on meaningful content. 
//         Lorem ipsum may be used as a placeholder before the final copy is available.
//       </p>
//     </div>
//   );
// };

// export default Content5;


import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './Content5.module.css';
import phone from '../../../assets/rival-phone.png';
import pay from '../../../assets/apple-pay.png';
import { MyContext } from '../../../Context/AuthContext';

const Content5 = () => {
  const contentRef = useRef(null);
  const [scale, setScale] = useState(2.5); // Start with a larger initial size

  useEffect(() => {
    const handleScroll = () => {
      const rect = contentRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Calculate how much of the container is visible
      const visibleRatio = Math.min(Math.max(0, (windowHeight - rect.top) / rect.height), 1);

      if (visibleRatio >= 0 && visibleRatio <= 1) {
        // Set scale based on visible ratio, normal size at 30% visibility
        const newScale = visibleRatio >= 0.3 ? 1 : 2.5 - visibleRatio * 5;
        setScale(newScale);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const {  anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen}  = useContext(MyContext)

  return (
    <div ref={contentRef} className={classes.home_content5_parent} onMouseEnter={handlePopoverClose}>
      <div
        className={classes.home_content5_child1}
        style={{
          transform: `scale(${scale})`,
          transition: 'transform 0.5s ease-out', // Smooth transition
        }}
      >
        <img src={phone} alt="phone-image" />
      </div>
      <div className={classes.home_content5_child2}>
        <img src={pay} alt="apple-pay" />
      </div>
      <p>
        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to 
        demonstrate the visual form of a document or a typeface without relying on meaningful content. 
        Lorem ipsum may be used as a placeholder before the final copy is available.
      </p>
    </div>
  );
};

export default Content5;
