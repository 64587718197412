import React, { useContext } from "react";
import "./MoneyTransfer.css";
import Money from "../../assets/greenmoney.png";
import Dollar from "../../assets/dollar.png";
import { Box, Typography } from "@mui/material";
import MobileSetion from "./MobileSetion";
import Communication from "./Communication";
import InternationalTransfer from "./InternationalTransfer";
import FinanceAppp from "../PersonelNavbar/FinanceApp"
import CountrySelect from "./CountrySelect";
import International from "../Card/International";
import { MyContext } from "../../Context/AuthContext";


const MoneyTransfer = () => {
  const {
    anchorEl,
    setAnchorEl,
    handlePopoverOpen,
    handlePopoverClose,
    isPopoverOpen,
  } = useContext(MyContext);

  return (
    <Box onMouseEnter={handlePopoverClose}>


    <Box className="moneyMainBox">
      <Box className="titleSide">
        <Typography className="subTitle">
          Overseas without the overspend
        </Typography>
        <Typography className="title">INTERNATIONAL MONEY TRANSFER</Typography>
        <Typography className="thirdTitle">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content. Lorem ipsum may be
          used as a placeholder before the final copy is available
        </Typography>
        <Typography className="forthTitle">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface.
        </Typography>
      </Box>
      <Box className="moneyImage">
        <img
          src={Money}
          alt="earth"
          style={{
            width: "100%",
            height: "auto",
            display: "block",
          }}
        />
        <Box className="dollarImage">
          <Box className="dollarInnerBox">
            <img
              src={Dollar}
              alt="dollar"
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
   <MobileSetion />
   <Communication />
      <InternationalTransfer />
    <FinanceAppp />
    <CountrySelect />
<International />
    </Box>
  );
};

export default MoneyTransfer;
