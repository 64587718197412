import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import './PersonelParagraph.css';

const PersonelPargraph = () => {
  return (
    <Box className="mainSectionBox" sx={{ padding: "0px 0px 80px 0px" }}>
      <Box className="firstPara">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box className="leftFirstPara">
              <Typography 
                sx={{ 
                  fontFamily: "SF Pro Display", 
                  fontSize: { xs: "16px", sm: "18px" }, 
                  fontWeight: 600, 
                  color: "#505053" 
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
                iure quasi deleniti, quae <span style={{ color: "white" }}>similique </span>tenetur! Reprehenderit soluta
                sequi inventore blanditiis delectus possimus odio unde voluptatum
                consectetur ratione, quo, eaque dolorem recusandae, quod enim fugiat
                dolores illum. Dicta,
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="leftFirstPara">
              <Typography 
                sx={{ 
                  fontFamily: "SF Pro Display", 
                  fontSize: { xs: "20px", sm: "25px" }, 
                  fontWeight: 600, 
                  color: "#fff", 
                  lineHeight: "30px" 
                }}
              >
                Highest-quality
              </Typography>
              <Typography 
                sx={{ 
                  fontFamily: "SF Pro Display", 
                  fontSize: { xs: "20px", sm: "25px" }, 
                  fontWeight: 600, 
                  color: "#505053", 
                  lineHeight: "28px" 
                }}
              >
                Video in a <br />SmartPhone
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      
      <Box className="firstPara">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                fontFamily: "SF Pro Display", 
                fontSize: { xs: "16px", sm: "18px" }, 
                fontWeight: 600, 
                color: "#505053" 
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
              <span style={{ color: "white" }}>Obcaecati iure quasi deleniti, quae similique tenetur!</span>
              sequi inventore blanditiis delectus possimus odio unde voluptatum
              consectetur ratione, quo, eaque dolorem recusandae, <span style={{ color: "white" }}>asperiores? Culpa nesciunt quasi</span>quod enim fugiat
              dolores illum. Dicta, vitae asperiores? Culpa nesciunt quasi
              corrupti debitis! Doloribus dolor dicta fuga porro, perspiciatis
              asperiores, tempore nihil quasi
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography 
              sx={{ 
                fontFamily: "SF Pro Display", 
                fontSize: { xs: "16px", sm: "18px" }, 
                fontWeight: 600, 
                color: "#505053" 
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
              iure quasi deleniti, quae similique tenetur! Reprehenderit soluta
              sequi inventore blanditiis delectus possimus odio unde voluptatum
              consectetur ratione, quo, eaque dolorem <span style={{ color: "white" }}>asperiores? Culpa nesciunt quasi</span> recusandae, quod enim fugiat
              dolores illum. Dicta, vitae asperiores? Culpa nesciunt quasi
              corrupti debitis! Doloribus dolor dicta fuga porro, perspiciatis
              asperiores, tempore nihil quasi dolores repellendus officiis qui
              iste enim soluta fugit consectetur dolor dicta fuga porro, perspiciatis
              asperiores, tempore nihil quasi dolores repellendus officiis qui
              iste enim soluta fugit consectetur??
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PersonelPargraph;
